@each $i in 0,4,8,16,24,32,48,64
{
  .HBox-#{$i} {
    display: flex;
    flex-direction: row;
    >* {
      margin-right: #{$i}px;
    }
    >*:last-child {
      margin-right: 0;
    }
  }

  .HBox-#{$i}.align_center{
    align-items: center;
  }

  .HBox-#{$i}.align_end{
    align-items: end;
  }
  .HBox-#{$i}.align_start{
    align-items: flex-start;
  }

  .VBox-#{$i}
  {
    display: flex;
    flex-direction: column;
    >* {
      margin-bottom: #{$i}px;
    }
    >*:last-child {
      margin-bottom: 0;
    }
  }
  .VBox-#{$i}.align_center{
    align-items: center;
  }
  .VBox-#{$i}.align_end{
    align-items: end;
  }
  .VBox-#{$i}.align_start{
    align-items: flex-start;
  }
}